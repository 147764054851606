// Fonts
@mixin marker {
  font-family: 'Permanent Marker', cursive;
  font-weight: 400;
}

@mixin livvic($weight: 400) {
  font-family: 'Livvic', sans-serif;
  font-weight: $weight;
}
