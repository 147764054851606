@import '../assets/css/global.scss';

.button {
  display: inline-block;
  border-width: .25rem;
  @include livvic(600);
  font-size: 1rem;
  padding: .4375rem 1rem;
  line-height: 1rem;
  color: black;
  background: white;
  border-radius: .5rem;
  border-style: solid;
  border-color: black;
  transition: all .2s ease;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
  @each $name, $color in $colorPallete {
    &.#{$name} {
      border-color: $color;
      color: $color;
      @media all and (min-width: 1200px) {
        &:hover {
          background: $color;
          color: white;
        }
      }
    }
  }
}
