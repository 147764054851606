// Colors
$brown: #885b4d;
$light-brown: #c18254;
$olive: #70754a;
$gold: #ecac42;
$red: #de5a4a;
$light-blue: #7eaeab;
$pink: #e66e97;
$lavendar: #7a83ae;
$light-pink: #F9C3D0;
$black: #000;

$colorPallete: (
  "brown": $brown,
  "lightBrown": $light-brown,
  "olive": $olive,
  "gold": $gold,
  "red": $red,
  "pink": $pink,
  "lightBlue": $light-blue,
  "lavendar": $lavendar,
  "lightPink": $light-pink,
  "black": $black,
);
