@import url(//fonts.googleapis.com/css?family=Livvic:400,600|Permanent+Marker&display=swap);
@import url(//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css);
.Button_button__LwMAv {
  display: inline-block;
  border-width: .25rem;
  font-family: 'Livvic', sans-serif;
  font-weight: 600;
  font-size: 1rem;
  padding: .4375rem 1rem;
  line-height: 1rem;
  color: black;
  background: white;
  border-radius: .5rem;
  border-style: solid;
  border-color: black;
  -webkit-transition: all .2s ease;
  transition: all .2s ease;
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5); }
  .Button_button__LwMAv.Button_brown__2iULA {
    border-color: #885b4d;
    color: #885b4d; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_brown__2iULA:hover {
        background: #885b4d;
        color: white; } }
  .Button_button__LwMAv.Button_lightBrown__3O5bF {
    border-color: #c18254;
    color: #c18254; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_lightBrown__3O5bF:hover {
        background: #c18254;
        color: white; } }
  .Button_button__LwMAv.Button_olive__2PU4e {
    border-color: #70754a;
    color: #70754a; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_olive__2PU4e:hover {
        background: #70754a;
        color: white; } }
  .Button_button__LwMAv.Button_gold__3IWF_ {
    border-color: #ecac42;
    color: #ecac42; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_gold__3IWF_:hover {
        background: #ecac42;
        color: white; } }
  .Button_button__LwMAv.Button_red__32uLq {
    border-color: #de5a4a;
    color: #de5a4a; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_red__32uLq:hover {
        background: #de5a4a;
        color: white; } }
  .Button_button__LwMAv.Button_pink__18x90 {
    border-color: #e66e97;
    color: #e66e97; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_pink__18x90:hover {
        background: #e66e97;
        color: white; } }
  .Button_button__LwMAv.Button_lightBlue__3BfCN {
    border-color: #7eaeab;
    color: #7eaeab; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_lightBlue__3BfCN:hover {
        background: #7eaeab;
        color: white; } }
  .Button_button__LwMAv.Button_lavendar__udcmR {
    border-color: #7a83ae;
    color: #7a83ae; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_lavendar__udcmR:hover {
        background: #7a83ae;
        color: white; } }
  .Button_button__LwMAv.Button_lightPink__1pMUl {
    border-color: #F9C3D0;
    color: #F9C3D0; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_lightPink__1pMUl:hover {
        background: #F9C3D0;
        color: white; } }
  .Button_button__LwMAv.Button_black__27OMP {
    border-color: #000;
    color: #000; }
    @media all and (min-width: 1200px) {
      .Button_button__LwMAv.Button_black__27OMP:hover {
        background: #000;
        color: white; } }

html, body {
  font-size: 100% !important;
  line-height: 1 !important; }

body {
  font-family: 'Livvic', sans-serif !important;
  color: white !important; }

.Home_container__2MKMA {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background: url(/static/media/background.1a1add8e.jpg) center 0 no-repeat !important;
  background-size: cover !important;
  padding: 1.75rem; }
  @media all and (min-width: 992px) and (min-height: 800px) {
    .Home_container__2MKMA {
      background: url(/static/media/background-fade.4f7cd5f7.jpg) center 0 no-repeat #000 !important;
      background-size: cover !important; } }
  .Home_container__2MKMA .Home_hgroup__1AasH {
    text-align: center;
    margin-bottom: 2.5rem; }
    .Home_container__2MKMA .Home_hgroup__1AasH h1, .Home_container__2MKMA .Home_hgroup__1AasH h2 {
      line-height: 2rem;
      font-family: 'Permanent Marker', cursive;
      font-weight: 400;
      margin: 0;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5); }
    .Home_container__2MKMA .Home_hgroup__1AasH h1 {
      color: #de5a4a;
      font-size: 2.5rem; }
    .Home_container__2MKMA .Home_hgroup__1AasH h2 {
      color: #e66e97;
      font-size: 2rem;
      margin-bottom: 1.75rem; }
    .Home_container__2MKMA .Home_hgroup__1AasH p {
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
      color: white;
      font-size: .75rem;
      line-height: 1.125rem;
      margin: 0; }
      .Home_container__2MKMA .Home_hgroup__1AasH p em {
        display: block;
        font-style: normal; }
    @media all and (min-width: 768px) {
      .Home_container__2MKMA .Home_hgroup__1AasH h1, .Home_container__2MKMA .Home_hgroup__1AasH h2 {
        line-height: 2.5rem; }
      .Home_container__2MKMA .Home_hgroup__1AasH h1 {
        font-size: 3rem; }
      .Home_container__2MKMA .Home_hgroup__1AasH h2 {
        font-size: 2.4375rem; }
      .Home_container__2MKMA .Home_hgroup__1AasH p {
        font-size: .875rem;
        line-height: 1.125rem; } }

.Home_albumLinks__3kZuY {
  max-width: 1200px;
  margin: 0 auto 3rem auto; }
  .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4 {
      font-family: 'Livvic', sans-serif;
      font-weight: 600;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
      font-size: 1.5rem;
      line-height: 1.5rem; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_brown__2-2O4, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_brown__2-2O4 {
        color: #885b4d; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_lightBrown__3bOeU, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_lightBrown__3bOeU {
        color: #c18254; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_olive__2jzdK, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_olive__2jzdK {
        color: #70754a; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_gold__3L1Yx, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_gold__3L1Yx {
        color: #ecac42; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_red__1_kRX, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_red__1_kRX {
        color: #de5a4a; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_pink__yxX9-, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_pink__yxX9- {
        color: #e66e97; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_lightBlue__35UwC, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_lightBlue__35UwC {
        color: #7eaeab; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_lavendar__2Y7_6, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_lavendar__2Y7_6 {
        color: #7a83ae; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_lightPink__PNi4u, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_lightPink__PNi4u {
        color: #F9C3D0; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h3.Home_black__33eNu, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4.Home_black__33eNu {
        color: #000; }
    .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz h4 {
      font-size: 1.375rem;
      line-height: 1.375rem; }
    .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz button, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz a {
      display: block;
      width: 100%;
      max-width: 225px;
      margin: 0 auto .5625rem auto; }
      @media all and (min-width: 768px) {
        .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz button, .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz a {
          max-width: 176px; } }
    .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_coverImage__1UVuh {
      width: 225px;
      margin: 0 auto;
      display: block;
      padding: 0;
      box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5); }
      @media all and (min-width: 768px) {
        .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_coverImage__1UVuh {
          max-width: 225px; } }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_coverImage__1UVuh img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%; }
    .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_instagram__16h_f {
      color: white;
      font-family: 'Livvic', sans-serif;
      font-weight: 600;
      font-size: .8125rem;
      text-decoration: underline; }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_instagram__16h_f:hover {
        text-decoration: none; }
    .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_darlaLogo__1ySMq {
      display: block;
      max-width: 225px;
      height: auto;
      margin: 2rem auto 0 auto; }
      @media all and (min-width: 768px) {
        .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_darlaLogo__1ySMq {
          max-width: 176px; } }
      .Home_albumLinks__3kZuY .Home_linkColumn__1pWSz .Home_darlaLogo__1ySMq img {
        max-width: 100%;
        height: auto; }

.Home_tour__KaZg3,
.Home_about__U3nc4 {
  margin: 0 auto 2rem auto;
  padding-bottom: 1rem;
  text-align: center; }
  .Home_tour__KaZg3 h1,
  .Home_about__U3nc4 h1 {
    line-height: 2.5rem;
    font-family: 'Permanent Marker', cursive;
    font-weight: 400;
    margin: 0 0 1rem 0;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    color: #ecac42;
    font-size: 2.5rem; }
  .Home_tour__KaZg3 p,
  .Home_about__U3nc4 p {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.5);
    max-width: 680px;
    margin: 0 auto 1rem auto; }
  .Home_tour__KaZg3 a,
  .Home_about__U3nc4 a {
    color: #fff;
    text-decoration: underline; }
    .Home_tour__KaZg3 a:hover,
    .Home_about__U3nc4 a:hover {
      text-decoration: none;
      color: #e66e97; }

.Home_about__U3nc4 h1 {
  color: #ecac42; }

.Home_footer__2Fvak {
  text-align: center;
  margin-top: 4rem;
  font-size: 10px; }
  .Home_footer__2Fvak a {
    display: inline-block;
    margin: 0 .5rem;
    color: #7a83ae; }
    .Home_footer__2Fvak a:hover {
      color: #7a83ae;
      text-decoration: underline; }

.SignIn_container__2hMzQ {
  background: #1b1c1d;
  height: 100vh;
  margin-top: 0 !important;
  margin-bottom: 0 !important; }

.SignIn_header__2p2Wn {
  color: white !important; }



