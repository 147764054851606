@import '../assets/css/global';

// Global & Overrides
html, body {
  font-size: 100% !important;
  line-height: 1 !important;
}

body {
  font-family: 'Livvic', sans-serif !important;
  color: white !important;
}

// Main Content
.container {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  height: 100vh;
  left: 0;
  right: 0;
  background: url(../images/background.jpg) center 0 no-repeat !important;
  background-size: cover !important;
  @media all and (min-width: 992px) and (min-height: 800px) {
    background: url(../images/background-fade.jpg) center 0 no-repeat #000 !important;
    background-size: cover !important;
  }
  padding: 1.75rem;
  .hgroup {
    text-align: center;
    margin-bottom: 2.5rem;
    h1, h2 {
      line-height: 2rem;
      @include marker;
      margin: 0;
      text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
    }
    h1 {
      color: $red;
      font-size: 2.5rem;
    }
    h2 {
      color: $pink;
      font-size: 2rem;
      margin-bottom: 1.75rem;
    }
    p {
      em {
        display: block;
        font-style: normal;
      }
      text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
      color: white;
      font-size: .75rem;
      line-height: 1.125rem;
      margin: 0;
    }
    @media all and (min-width: 768px) {
      h1, h2 {
        line-height: 2.5rem;
      }
      h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 2.4375rem;
      }
      p {
        font-size: .875rem;
        line-height: 1.125rem;
      }
    }
  }
}

.albumLinks {
  max-width: 1200px;
  margin: 0 auto 3rem auto;
  .linkColumn {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    h3, h4 {
      @include livvic(600);
      text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
      font-size: 1.5rem;
      line-height: 1.5rem;
      @each $name, $color in $colorPallete {
        &.#{$name} {
          color: $color;
        }
      }
    }
    h4 {
      font-size: 1.375rem;
      line-height: 1.375rem;
    }
    button, a {
      display: block;
      width: 100%;
      max-width: 225px;
      margin: 0 auto .5625rem auto;
      @media all and (min-width: 768px) {
        max-width: 176px;
      }
    }
    .coverImage {
      width: 225px;
      margin: 0 auto;
      display: block;
      padding: 0;
      box-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
      @media all and (min-width: 768px) {
        max-width: 225px;
      }
      img {
        display: block;
        width: auto;
        height: auto;
        max-width: 100%;
      }
    }
    .instagram {
      color: white;
      @include livvic(600);
      font-size: .8125rem;
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    }
    .darlaLogo {
      display: block;
      max-width: 225px;
      height: auto;
      margin: 2rem auto 0 auto;
      @media all and (min-width: 768px) {
        max-width: 176px;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}

.tour,
.about {
  margin: 0 auto 2rem auto;
  padding-bottom: 1rem;
  text-align: center;
  h1 {
    line-height: 2.5rem;
    @include marker;
    margin: 0 0 1rem 0;
    text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
    color: $gold;
    font-size: 2.5rem;
  }
  p {
    text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
    max-width: 680px;
    margin: 0 auto 1rem auto;
  }
  a {
    color: #fff;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
      color: $pink;
    }
  }
}

.about h1 {
  color: $gold;
}

.footer {
  text-align: center;
  margin-top: 4rem;
  font-size: 10px;
  a {
    display: inline-block;
    margin: 0 .5rem;
    color: $lavendar;
    &:hover {
      color: $lavendar;
      text-decoration: underline;
    }
  }
}